var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.changeActive(_vm.course)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('h3',{staticClass:"mt-3"},[_vm._v("Khóa học")])]},proxy:true}]),model:{value:(_vm.enable_courses),callback:function ($$v) {_vm.enable_courses=$$v},expression:"enable_courses"}}),_c('ValidationObserver',{ref:"affiliate_course"},[_c('h5',[_vm._v(" "+_vm._s(_vm.lang.title.payment)),_c('span',{staticClass:"red--text mr-4"},[_vm._v("(*)")])]),_c('v-row',{staticClass:"ma-0 pa-0 mt-3"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":!_vm.enable_courses,"items":_vm.paymentMethod,"dense":"","item-text":"label","outlined":"","color":"primary"},model:{value:(_vm.course.type),callback:function ($$v) {_vm.$set(_vm.course, "type", $$v)},expression:"course.type"}})]}}])})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"price","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{staticClass:"money-box-input",attrs:{"label":'',"placeholder":'Nhập số tiền khuyến mại',"readonly":false,"disabled":!_vm.enable_courses,"outlined":true,"clearable":true,"valueWhenIsEmpty":'',"options":_vm.options},model:{value:(_vm.course.amount_money),callback:function ($$v) {_vm.$set(_vm.course, "amount_money", $$v)},expression:"course.amount_money"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('div',{staticClass:"text-h6 ml-1"},[_vm._v(" "+_vm._s(_vm.lang.title.describe)+" ")]),_c('div',{staticClass:"mt-5"},[_c('hnr-textarea',{staticClass:"input-textArea",attrs:{"disabled":!_vm.enable_courses,"label":_vm.lang.label.describe},model:{value:(_vm.course.description),callback:function ($$v) {_vm.$set(_vm.course, "description", $$v)},expression:"course.description"}})],1)],1),_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.changeActive(_vm.book)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('h3',{staticClass:"mt-3"},[_vm._v("Sách")])])]},proxy:true}]),model:{value:(_vm.enable_books),callback:function ($$v) {_vm.enable_books=$$v},expression:"enable_books"}}),_c('ValidationObserver',{ref:"affiliate_book"},[_c('div',{staticClass:"text-h6 ml-1"},[_vm._v(" "+_vm._s(_vm.lang.title.payment)),_c('span',{staticClass:"red--text mr-4"},[_vm._v("(*)")])]),_c('v-row',{staticClass:"ma-0 pa-0 mt-3"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"shrink money-box",attrs:{"disabled":!_vm.enable_books,"items":_vm.paymentMethod,"dense":"","item-text":"label","outlined":"","color":"primary"},model:{value:(_vm.book.type),callback:function ($$v) {_vm.$set(_vm.book, "type", $$v)},expression:"book.type"}})]}}])})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"price","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{staticClass:"money-box-input",attrs:{"label":'',"placeholder":'Nhập số tiền khuyến mại',"readonly":false,"disabled":!_vm.enable_books,"outlined":true,"clearable":true,"valueWhenIsEmpty":'',"options":_vm.options},model:{value:(_vm.book.amount_money),callback:function ($$v) {_vm.$set(_vm.book, "amount_money", $$v)},expression:"book.amount_money"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.lang.title.describe)+" ")]),_c('div',{staticClass:" mt-5"},[_c('hnr-textarea',{staticClass:"input-textArea",attrs:{"disabled":!_vm.enable_books,"label":_vm.lang.label.describe},model:{value:(_vm.book.description),callback:function ($$v) {_vm.$set(_vm.book, "description", $$v)},expression:"book.description"}})],1)],1),_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.changeActive(_vm.combo)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('h3',{staticClass:"mt-3"},[_vm._v("Khóa học 1-1")])])]},proxy:true}]),model:{value:(_vm.enable_combos),callback:function ($$v) {_vm.enable_combos=$$v},expression:"enable_combos"}}),_c('ValidationObserver',{ref:"affiliate_combo"},[_c('div',{staticClass:"text-h6 ml-1"},[_vm._v(" "+_vm._s(_vm.lang.title.payment)),_c('span',{staticClass:"red--text mr-4"},[_vm._v("(*)")])]),_c('v-row',{staticClass:"ma-0 pa-0 mt-3"},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"3"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"shrink money-box",attrs:{"disabled":!_vm.enable_combos,"items":_vm.paymentMethod,"dense":"","item-text":"label","outlined":"","color":"primary"},model:{value:(_vm.combo.type),callback:function ($$v) {_vm.$set(_vm.combo, "type", $$v)},expression:"combo.type"}})]}}])})],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"7"}},[_c('ValidationProvider',{attrs:{"name":"price","rules":"required|number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vuetify-money',{staticClass:"money-box-input",attrs:{"label":'',"placeholder":'Nhập số tiền khuyến mại',"readonly":false,"disabled":!_vm.enable_combos,"outlined":true,"clearable":true,"valueWhenIsEmpty":'',"options":_vm.options},model:{value:(_vm.combo.amount_money),callback:function ($$v) {_vm.$set(_vm.combo, "amount_money", $$v)},expression:"combo.amount_money"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.lang.title.describe)+" ")]),_c('div',{staticClass:" mt-5"},[_c('hnr-textarea',{staticClass:"input-textArea",attrs:{"disabled":!_vm.enable_combos,"label":_vm.lang.label.describe},model:{value:(_vm.combo.description),callback:function ($$v) {_vm.$set(_vm.combo, "description", $$v)},expression:"combo.description"}})],1)],1),_c('v-col',{staticClass:"mx-auto text-right",attrs:{"cols":"8"}},[_c('hnr-button',{attrs:{"type":"submit","form":"check-form","rounded":"","width":"200","text":"Tạo mã giới thiệu","color":"blue","white-text":"true"},on:{"hnrClickEvent":_vm.saveCode}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }